import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { XEvent } from '@kireidy/definitions';

import { OnlineService } from '@kireidy/services';
import { OverlayService } from '@kireidy/wild-ui';

import { InscriptionModalComponent } from '../inscription-modal/inscription-modal.component';
import { ModalConfirmComponent } from '../../../shared/components/modal-confirm/modal-confirm.component';

class Paragraphes {
    public items = new Array<string>();
    public index = 0;
    public output = '';
}


@Component({
    selector: 'kireidy-inscription',
    templateUrl: './inscription.component.html',
    styleUrls: ['./inscription.component.scss']
})

export class InscriptionComponent implements OnInit {

    public isContinueAnimated = true;
    public isLoading = false;
    public isJoigned = false;
    public invalidity: string | null = null;
    public warning: string | null = null;

    public event = new XEvent();

    public paragraphes = new Paragraphes();

    public group = '';
    public pseudo = '';
    public email = '';
    public emailConfirmation = '';


    constructor(
        private title: Title,
        private meta: Meta,
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private overlayService: OverlayService,
        private onlineService: OnlineService) { }

    ngOnInit(): void {

        // Update on init
        this.update();

        // Update on reopen (wakeup, tab change...)
        document.onvisibilitychange = () => {
            if (!document.hidden) {
                this.update();
            }
        };
    }

    public get image(): string {

        return this.event.id ? this.onlineService.backendUrl + '/events/' + this.event.id + '/image' : '';
    }

    public get isFull(): boolean {

        if (!this.event?.limit || !this.event?.members) {
            return false;
        }

        return this.event.limit > 0 && this.event.members.length >= this.event.limit;
    }

    public get isPassed(): boolean {

        if (!this.event.date) {
            return false;
        }

        return this.event.date < new Date();
    }

    private update(): void {

        this.isLoading = true;

        const eventId = this.route.snapshot.paramMap.get('eventId');

        if (!eventId) {

            return;
        }

        this.onlineService
            .getEvent(eventId)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: (response) => {

                    this.event = response;

                    this.title.setTitle(this.event.name || '');

                    this.meta.addTag({property: 'og:title',         content: this.event.name || ''});
                    this.meta.addTag({property: 'og:description',   content: 'join this event'});
                    this.meta.addTag({property: 'og:image',         content: this.image});
                    this.meta.addTag({property: 'twitter:image',    content: this.image});

                    this.paragraphesStart();
                },
                error: (response) => {

                    console.error(response.error.message);

                    this.overlayService.openNotification({
                        message: this.translateService.instant('event.notFound'),
                        type: 'error'
                    });

                    this.router.navigateByUrl('/');
                }
            });
    }

    private paragraphesStart(): void {

        if (this.event.description.length === 0) {

            console.log(`Nothing to describe.`);

            this.paragraphesJumpToEnd();

            return;
        }

        if (this.event.description.startsWith('# ') === false) {

            console.log(`Not using paragraphes properly`);

            this.paragraphesJumpToEnd();

            return;
        }

        if (this.event.isTimedReading === false ||      // Not timed by event options
            this.route.snapshot.queryParams['skip']) {  // Not timed by query param 'skip=true'

            this.paragraphesJumpToEnd();

            return;
        }

        // Reset
        this.paragraphes = new Paragraphes();

        // Split the description of the event using top rank titles (single #)
        this.paragraphes.items = this.event.description
            .split(/[^#]#[^#]/gm)
            .map(paragraph => (paragraph.startsWith('# ') ? '' : '# ') + paragraph);

        this.paragraphes.items.push('');

        this.paragraphesNext();
    }

    public paragraphesNext(): void {

        if (this.isParagraphesReading) {

            this.paragraphes.output += this.paragraphes.items[this.paragraphes.index++];

            if (!this.isParagraphesReading) {
                localStorage.setItem(this.event.id || '', 'description already readed');
            }

            this.isContinueAnimated = false;

            setTimeout(() => this.isContinueAnimated = true, 0);
        }
    }

    private paragraphesJumpToEnd(): void {

        this.isContinueAnimated = false;

        this.paragraphes.index = this.paragraphes.items.length;

        this.paragraphes.output = this.event.description;
    }

    public get isParagraphesReading(): boolean {

        return this.paragraphes.items.length > 0 && this.paragraphes.index < this.paragraphes.items.length;
    }

    public async join(): Promise<void> {

        this.isJoigned = await this.overlayService.openModal({
            component: InscriptionModalComponent,
            inputs: {
                event: this.event
            }
        });
    }

    public async remix(): Promise<void> {

        if (await this.overlayService.openModal({
            component: ModalConfirmComponent,
            inputs: {
                description: this.translateService.instant('inscription.remix.description')
            }
        }) !== true) {

            return;
        }

        this.router.navigate(['events', 'create'], { queryParams: { from: this.event.id } });
    }

}
