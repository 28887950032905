import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkdownPipe } from './pipes/markdown/markdown.pipe';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { LoadingComponent } from './components/loading/loading.component';
import { EntryComponent } from './components/entry/entry.component';
import { TranslateModule } from '@ngx-translate/core';
import { ModalLayoutComponent } from './components/modal-layout/modal-layout.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { WildUiModule } from '@kireidy/wild-ui';
import { KireidyServicesModule } from '@kireidy/services';

@NgModule({
    declarations: [
        MarkdownPipe,

        ModalLayoutComponent,
        ModalConfirmComponent,
        IconButtonComponent,

        HeaderComponent,
        FooterComponent,
        LoadingComponent,
        EntryComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        WildUiModule,
        KireidyServicesModule
    ],
    exports: [
        TranslateModule,
        WildUiModule,

        MarkdownPipe,

        ModalLayoutComponent,
        ModalConfirmComponent,
        ModalLayoutComponent,
        IconButtonComponent,

        HeaderComponent,
        FooterComponent,
        LoadingComponent,
        EntryComponent,
    ],
})

export class SharedModule { }
