@if(isUnsubscribed) {
    <div class="description">
        {{ 'unsubscription.done' | translate }}
    </div>
} @else {

    <div class="description">
        {{ 'unsubscription.title' | translate }}
    </div>
    
    <kireidy-entry class="reason">
        <div title>{{ 'unsubscription.reason.title' | translate }}</div>
        <div detail>{{ 'unsubscription.reason.detail' | translate }}</div>
        <ng-container value>
            <textarea
                [(ngModel)]="request.reason">
            </textarea>
        </ng-container>
    </kireidy-entry>

    <div class="buttons">

        <kireidy-icon-button
            class="confirm"
            (click)="unsubscribe()"
            [class.disabled]="isLoading"
            [class.pulsing-fast]="isLoading"
            [title]="'shared.confirm' | translate">
            ✔
        </kireidy-icon-button>
    
    </div>
}
