import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { finalize } from 'rxjs/operators';
import { UnsubscribeMemberRequest } from '@kireidy/definitions';
import { OnlineService } from '@kireidy/services';
import { OverlayService } from '@kireidy/wild-ui';

@Component({
    selector: 'kireidy-unsubscription',
    templateUrl: './unsubscription.component.html',
    styleUrls: ['./unsubscription.component.scss']
})
export class UnsubscriptionComponent implements OnInit {

    public eventId = '';
    public memberId = '';
    public request = new UnsubscribeMemberRequest();

    public isLoading = false;
    public isUnsubscribed = false;

    constructor(
        private title: Title,
        private meta: Meta,
        private route: ActivatedRoute,
        private overlayService: OverlayService,
        private onlineService: OnlineService) { }

    ngOnInit(): void {

        this.title.setTitle('Annulation');

        this.eventId = this.route.snapshot.paramMap.get('eventId') || '';
        this.memberId = this.route.snapshot.paramMap.get('memberId') || '';
        this.request.key = this.route.snapshot.paramMap.get('key') || '';
    }

    public unsubscribe(): void {

        this.isLoading = true;

        this.onlineService
            .unsubscribeMember(this.eventId, this.memberId, this.request)
            .pipe(finalize(() => { this.isLoading = false; }))
            .subscribe({
                next: () => {
                    this.isUnsubscribed = true;
                },
                error: (response) => {
                    this.overlayService.openNotification({
                        message: response.error.message,
                        type: 'error'
                    });
                }
            });
    }

}
