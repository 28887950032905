// Allows usage of the Buffer type
//import { Utils } from 'tslint';

import { HTTP_INTERCEPTORS, HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EditionModule } from './edition/edition.module';
import { InscriptionModule } from './inscription/inscription.module';
import { LandingModule } from './landing/landing.module';
import { SharedModule } from './shared/shared.module';
import { UnsubscriptionModule } from './unsubscription/unsubscription.module';
import { MarkdownModule } from './markdown/markdown.module';
import { LOCATION_INITIALIZED } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { StyleUtils } from './shared/utils/style.utils';
import { OverlayService } from '@kireidy/wild-ui';
import { JsonDateInterceptor } from '@kireidy/services';

export const httpLoaderFactory = (http: HttpBackend): MultiTranslateHttpLoader => {
    return new MultiTranslateHttpLoader(http, [
        'assets/i18n/core/',
        // 'assets/i18n/shared/',
        // 'assets/i18n/http/',
    ]);
};

const DefaultLanguage = 'fr';

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return async () => {

        await injector.get(LOCATION_INITIALIZED, Promise.resolve());

        translate.setDefaultLang(DefaultLanguage);

        await firstValueFrom(translate.use(DefaultLanguage));
    }
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpBackend],
            },
        }),
        SharedModule,
        LandingModule,
        MarkdownModule,
        InscriptionModule,
        EditionModule,
        UnsubscriptionModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
        {   provide: HTTP_INTERCEPTORS,
            useClass: JsonDateInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(overlayService: OverlayService) {

        // Register notifications styles
        overlayService.notificationsStyles = [
            {
                type: 'success',
                backgroundColor: StyleUtils.getVariable('--color-background-success'),
                foregroundColor: StyleUtils.getVariable('--color-text-success')
            },
            {
                type: 'warning',
                backgroundColor: StyleUtils.getVariable('--color-background-warning'),
                foregroundColor: StyleUtils.getVariable('--color-text-warning')
            },
            {
                type: 'error',
                backgroundColor: StyleUtils.getVariable('--color-background-error'),
                foregroundColor: StyleUtils.getVariable('--color-text-error')
            }
        ]
    }
}
