
<section>
    <header>Kireidy</header>

    Kireidy est un service d'inscription minimaliste pour vos évènements.<br/>

</section>

<section>
    <header>Simple et immédiat</header>
    <p>
        Avec Kireidy, pas besoin de compte, vous pouvez créer des événements ou vous y inscrire juste avec une adresse email valide.
    </p>
    <p>
        C’est une alternative aux systèmes d’inscriptions présents sur les réseaux sociaux, souvent contraignants et confus.
    </p>
</section>

<section>
    <header>Inscription multiple</header>

    Vous pouvez réaliser plusieurs inscriptions sur votre même adresse email si vous représentez un groupe. Chaque inscription est accompagnée d’un email vous permettant de la gérer individuellement par la suite.
</section>

<section>
    <header>Descriptions structurées</header>

   Parceque personne ne lit les pavés de texte interminables, Kireidy supporte la notation <a routerLink="/markdown">markdown</a> et vous permet de structurer la description de vos évènements.
</section>

<section>
    <header>Commencer</header>

    <p>
        Créez votre premier événement maintenant!
    </p>

    <kireidy-icon-button (click)="onStart()" class="create pulsing-slow">+</kireidy-icon-button>

</section>
