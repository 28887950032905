export class Webhook {

    public id?: any;

    public description = '';

    public event = '';

    public method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTION' = 'POST';

    public url = '';

    public body = {};

    // public headers = {};
}
