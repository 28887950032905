import { HttpClient, HttpParams } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
    XEvent,
    CreateXEventRequest,
    UpdateXEventRequest,
    CreateXEventResponse,
    SubscribeMemberRequest,
    SubscribeMemberResponse,
    UnsubscribeMemberRequest,
    UnsubscribeMemberResponse,
    Host,
    DeleteXEventRequest,
    DeleteXEventResponse,
    AccountJWTPayload,
    LoginRequest,
    LoginResponse,
    RenewPasswordConfirmRequest,
    RenewPasswordRequest,
    SigninConfirmRequest,
    SigninRequest,
    Dashboard,
    AccountsSearchRequest,
    AccountsSearchResponse,
    XEventsSearchRequest,
    XEventsSearchResponse,
    Journal
} from '@kireidy/definitions';
import { AppsUtils } from '@kireidy/configuration';

const STORAGE_NAME_TOKEN = 'token';

@Injectable({
    providedIn: 'root'
})
export class OnlineService {

    public token = signal<string | undefined>(undefined);

    private _backendUrl: string;
    private _tokenPayload: AccountJWTPayload | undefined;
    private _expiration: Date | undefined;

    constructor(private http: HttpClient) {

        // Load autentication token
        const storedToken = localStorage.getItem(STORAGE_NAME_TOKEN);

        if (storedToken) {
            this.updateToken(storedToken);
        }

        // Get the url of the backend
        this._backendUrl =
            new URLSearchParams(window.location.search).get('backendUrl') ||    // Given by the host (electron app)
            AppsUtils.resolveAppUrl('backend') ||                               // Resolved from the current url (web app)
            '';                                                                 // Unknown

    }

    public get backendUrl(): string {

        return this._backendUrl;
    }


    // ------------------------------------------------------------------------------------
    // Authentication
    // ------------------------------------------------------------------------------------

    public updateToken(token: string | undefined) {

        this.token.set(token);

        this._tokenPayload = token ? JSON.parse(atob(token.split('.')[1])) : undefined;

        if (this._tokenPayload) {

            // The timestamps in JWT are UNIX timestamps counting from 01.01.1970 00:00 UTC
            this._expiration = new Date(0);
            this._expiration.setUTCSeconds(this._tokenPayload.exp);

        } else {

            this._expiration = undefined;
        }

        localStorage.setItem(STORAGE_NAME_TOKEN, token || '');
    }

    public get expiration(): Date | undefined {

        return this._expiration;
    }

    public get tokenPayload(): AccountJWTPayload | undefined {

        // Don't get an invalid token payload
        if (this.isTokenValid() === false) {
            return undefined;
        }

        return this._tokenPayload;
    }

    public isTokenValid = computed<boolean>(() => {
        return (
            this.token() !== undefined &&
            this._expiration !== undefined &&
            this._expiration.getTime() > new Date().getTime());
    });

    public signin(request: SigninRequest): Observable<void> {

        return this.http.post<void>(`${this.backendUrl}/accounts/signin`, request);
    }

    public signinConfirm(request: SigninConfirmRequest): Observable<AccountJWTPayload | undefined> {

        return this.http.post<LoginResponse>(`${this.backendUrl}/accounts/signin-confirm`, request)
            .pipe(map(response => {

                this.updateToken(response.token);

                return this.tokenPayload;
            }));
    }

    public renewPassword(request: RenewPasswordRequest): Observable<void> {

        return this.http.post<void>(`${this.backendUrl}/accounts/renew-password`, request);
    }

    public renewPasswordConfirm(request: RenewPasswordConfirmRequest): Observable<AccountJWTPayload | undefined> {

        return this.http.post<LoginResponse>(`${this.backendUrl}/accounts/renew-password-confirm`, request)
            .pipe(map(response => {

                this.updateToken(response.token);

                return this.tokenPayload;
            }));
    }

    public getFreshToken(): Observable<AccountJWTPayload | undefined> {

        return this.http.get<LoginResponse>(`${this.backendUrl}/accounts/token`)
            .pipe(map(response => {

                this.updateToken(response.token);

                return this.tokenPayload;
            }));
    }

    public login(request: LoginRequest): Observable<AccountJWTPayload | undefined> {

        return this.http.post<LoginResponse>(`${this.backendUrl}/accounts/login`, request)
            .pipe(map(response => {

                this.updateToken(response.token);

                return this.tokenPayload;
            }));
    }

    public logout(): void {

        this.updateToken(undefined);

        localStorage.removeItem('token');
    }





    // ------------------------------------------------------------------------------------
    // Accounts
    // ------------------------------------------------------------------------------------

    public searchAccounts(request: AccountsSearchRequest): Observable<AccountsSearchResponse> {

        const params = request as any;

        return this.http.get<AccountsSearchResponse>(`${this.backendUrl}/accounts`, { params });
    }

    public deleteAccount(id: string): Observable<void> {

        return this.http.delete<void>(`${this.backendUrl}/accounts/${id}`);
    }


    // ------------------------------------------------------------------------------------
    // Events
    // ------------------------------------------------------------------------------------

    public searchEvents(request: XEventsSearchRequest): Observable<XEventsSearchResponse> {

        const params = request as any;

        return this.http.get<XEventsSearchResponse>(`${this.backendUrl}/events`, { params });
    }

    public getEvent(eventId: string, key = ''): Observable<XEvent> {

        const params = (key ? { key } : {}) as any;

        return this.http.get<XEvent>(`${this._backendUrl}/events/${eventId}`, { params });
    }

    public createEvent(request: CreateXEventRequest): Observable<CreateXEventResponse> {

        return this.http.post<CreateXEventResponse>(`${this._backendUrl}/events`, request);
    }

    public deleteEvent(eventId: string, request: DeleteXEventRequest): Observable<DeleteXEventResponse> {

        const params = new HttpParams({ fromObject: { ...request }});

        return this.http.delete<DeleteXEventResponse>(`${this._backendUrl}/events/${eventId}`, { params });
    }

    public updateEvent(eventId: string, request: UpdateXEventRequest): Observable<boolean> {

        return this.http.patch<boolean>(`${this._backendUrl}/events/${eventId}`, request);
    }


    public subscribeMember(eventId: string, request: SubscribeMemberRequest): Observable<SubscribeMemberResponse> {

        return this.http.post<SubscribeMemberResponse>(`${this._backendUrl}/events/${eventId}/members`, request);
    }

    public unsubscribeMember(eventId: string, memberId: string, request: UnsubscribeMemberRequest): Observable<UnsubscribeMemberResponse> {

        const params = new HttpParams({ fromObject: { ...request }});

        return this.http.delete<UnsubscribeMemberResponse>(`${this._backendUrl}/events/${eventId}/members/${memberId}`, { params });
    }

    // ------------------------------------------------------------------------------------
    // Overview
    // ------------------------------------------------------------------------------------

    public getHost(): Observable<Host> {

        return this.http.get<Host>(`${this.backendUrl}/state/host`);
    }

    public getJournal(): Observable<Journal> {

        return this.http.get<Journal>(`${this.backendUrl}/state/journal`);
    }

    public getDashboard(): Observable<Dashboard> {

        return this.http.get<Dashboard>(`${this.backendUrl}/dashboard`);
    }

}
