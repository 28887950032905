
<kireidy-entry class="name">
    <div title>{{ 'edition.name.title' | translate }} *</div>
    <div detail>{{ 'edition.name.detail' | translate }}</div>
    <input
        value
        type="text"
        [(ngModel)]="event.name"
        (input)="checkValidity()">
</kireidy-entry>

<kireidy-entry class="limit">
    <div title>{{ 'edition.limit.title' | translate }} *</div>
    <div detail>{{ 'edition.limit.detail' | translate }}</div>
    <input
        value
        type="number"
        min="0"
        title="limite"
        [(ngModel)]="event.limit">
</kireidy-entry>

<kireidy-entry class="organisator">
    <div title>{{ 'edition.organisators.title' | translate }} *</div>
    <div detail>{{ 'edition.organisators.detail' | translate }}</div>
    <input
        value
        type="text"
        [(ngModel)]="event.organisator"
        (input)="checkValidity()">
</kireidy-entry>

<kireidy-entry class="date">
    <div title>{{ 'edition.date.title' | translate }} *</div>
    <div detail>{{ 'edition.date.detail' | translate }}</div>
    <input
        value
        type="date"
        [ngModel]="event.date | date:'yyyy-MM-dd'"
        (ngModelChange)="updateDate($event)"
        (input)="checkValidity()">
</kireidy-entry>

<kireidy-entry class="time">
    <div title>{{ 'edition.time.title' | translate }} *</div>
    <div detail>{{ 'edition.time.detail' | translate }}</div>
    <input
        value
        type="time"
        [ngModel] ="event.date | date:'hh:mm'"
        (ngModelChange)="updateTime($event)"
        (input)="checkValidity()">
</kireidy-entry>

<kireidy-entry class="location">
    <div title>{{ 'edition.location.title' | translate }} *</div>
    <div detail>{{ 'edition.location.detail' | translate }}</div>
    <input
        value
        type="text"
        [(ngModel)]="event.location"
        (input)="checkValidity()">
</kireidy-entry>

<kireidy-entry class="image">
    <div title>{{ 'edition.image.title' | translate }} *</div>
    <div detail>{{ 'edition.image.detail' | translate }}</div>
    <div
        value
        class="content"
        (click)="loadImage()"
        [style.backgroundImage]="'url(' + image + ')'"
        [style.backgroundPosition]="event.imagePosition">

        <div class="position">
            <div class="lt" (click)="setImagePosition('left top');       $event.stopPropagation()"></div>
            <div class="ct" (click)="setImagePosition('center top');     $event.stopPropagation()"></div>
            <div class="rt" (click)="setImagePosition('right top');      $event.stopPropagation()"></div>
            <div class="lc" (click)="setImagePosition('left center');    $event.stopPropagation()"></div>
            <div class="cc" (click)="setImagePosition('center center');  $event.stopPropagation()"></div>
            <div class="rc" (click)="setImagePosition('right center');   $event.stopPropagation()"></div>
            <div class="lb" (click)="setImagePosition('left bottom');    $event.stopPropagation()"></div>
            <div class="cb" (click)="setImagePosition('center bottom');  $event.stopPropagation()"></div>
            <div class="rb" (click)="setImagePosition('right bottom');   $event.stopPropagation()"></div>
        </div>
    </div>
</kireidy-entry>

<kireidy-entry class="description">
    <div title>{{ 'edition.description.title' | translate }} *</div>
    <div detail>
        <span>{{ 'shared.preview' | translate }}</span>
        <input type="checkbox" [(ngModel)]="isPreviewingDescription">
    </div>
    <ng-container value>
        <textarea
            [(ngModel)]="event.description">
        </textarea>

        @if(isPreviewingDescription) {
            <div class="preview"
                [innerHtml]="event.description | markdown">
            </div>
        }
    </ng-container>
</kireidy-entry>

@if(isKPlusAdministrator) {
    <kireidy-entry class="questions">
        <div title>💎 {{ 'edition.questions.title' | translate }}</div>
        <div detail>
            <span>{{ 'shared.preview' | translate }}</span>
            <input type="checkbox" [(ngModel)]="isPreviewingQuestions">
        </div>
        <ng-container value>
            <textarea
                #questionsField
                [ngModel]="questions" (keyup)="updateQuestions(questionsField.value)">
            </textarea>

            @if(isPreviewingQuestions) {
                <div class="preview">
                    @for(question of event.questions; track question) {
                        <kireidy-entry>
                            <div title>{{ question.name | capitalize }}</div>
                                @if(question.choices.length) {
                                    <select value>
                                        @for(choice of question.choices; track choice) {
                                            <option>{{ choice }}</option>
                                        }
                                    </select>
                                } @else {
                                    <input value type="text">
                                }
                        </kireidy-entry>
                    }
                </div>
            }
        </ng-container>
    </kireidy-entry>
}

<kireidy-entry class="email">
    <div title>
        @if(isKPlusAdministrator) { 💎 }
        {{ 'edition.administratorEmail.title' | translate }} *
    </div>
    <div detail>{{ 'edition.administratorEmail.detail' | translate }}</div>
    <input
        value
        type="email"
        name="email"
        [(ngModel)]="event.email"
        (input)="checkValidity()"/>
</kireidy-entry>

@if (event.members.length > 0) {
    <kireidy-entry class="members">
        <div title>{{ 'edition.members.title' | translate }} *</div>
        <div detail>{{ 'edition.members.detail' | translate }}</div>
        <div value class="content" (click)="copyMembers()">
        @for (member of event.members; track member; let index = $index) {
            <div class="member">

                <div class="number">
                    {{ index + 1 | number:'2.0' }}
                </div>

                <div class="information">

                    <div class="main">
                        {{ member.pseudo }}

                        @if(member.group) {
                            ({{ member.group }})
                        }
                    </div>

                    <div class="details">
                        <div class="detail">{{ member.email }}</div>
                        <div class="detail">{{ member.firstName }}</div>
                        <div class="detail">{{ member.lastName }}</div>
                        <div class="detail">{{ member.phone }}</div>
                        <div class="detail">{{ member.dob | date: 'dd/MM/yyyy' }}</div>
                    </div>

                    <div class="details">
                        @for(response of member.responses; track response) {
                            @if(response.value) {
                                <div class="detail">{{ response.name }} : {{ response.value }}</div>
                            }
                        }
                    </div>

                </div>

                <kireidy-icon-button class="remove" wuiStopPropagation (click)="unsubscribeMember(member)">❌</kireidy-icon-button>
            </div>
        }
        </div>
    </kireidy-entry>
}

<div class="options">

    <kireidy-entry>
        <div title>{{ 'edition.options.simplifiedInscription.title' | translate }}</div>
        <div detail>{{ ('edition.options.simplifiedInscription.detail.' + (event.isSimplifiedInscription ? 'yes' : 'no')) | translate }}</div>
        <select
            value
            [(ngModel)]="event.isSimplifiedInscription">
            <option [ngValue]="true">{{ 'shared.yes' | translate }}</option>
            <option [ngValue]="false">{{ 'shared.no' | translate }}</option>
        </select>
    </kireidy-entry>

    <kireidy-entry>
        <div title>{{ 'edition.options.timedReading.title' | translate }}</div>
        <div detail>{{ ('edition.options.timedReading.detail.' + (event.isTimedReading ? 'yes' : 'no')) | translate }}</div>
        <select
            value
            [(ngModel)]="event.isTimedReading">
            <option [ngValue]="true">{{ 'shared.yes' | translate }}</option>
            <option [ngValue]="false">{{ 'shared.no' | translate }}</option>
        </select>
    </kireidy-entry>

</div>

@if (invalidity) {
    <div class="invalidity">{{ invalidity }}</div>
}

<div class="buttons">

    <kireidy-icon-button
        (click)="deleteEvent()"
        [class.disabled]="!event.id || isLoading"
        [title]="'edition.buttons.delete' | translate">
        ❌
    </kireidy-icon-button>

    <kireidy-icon-button
        (click)="save()"
        [class.disabled]="isLoading || isIncomplete"
        [class.pulsing-fast]="isLoading"
        [title]="'edition.buttons.save' | translate">
        💾
    </kireidy-icon-button>

    <kireidy-icon-button
        (click)="view()"
        [class.disabled]="!event.id || isLoading"
        [title]="'edition.buttons.view' | translate">
        👓
    </kireidy-icon-button>

    <kireidy-icon-button
        (click)="share()"
        [class.disabled]="!event.id"
        [title]="'edition.buttons.link' | translate">
        🔗
    </kireidy-icon-button>

</div>
