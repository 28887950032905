import { Component, HostListener, Input } from '@angular/core';
import { OverlayService } from '@kireidy/wild-ui';

export class EditionCancelMemberResult {

    public reason = '';

}

@Component({
    selector: 'kireidy-edition-unsubscription-modal',
    templateUrl: './edition-unsubscription-modal.component.html',
    styleUrls: ['./edition-unsubscription-modal.component.scss'],
})
export class EditionUnsubscriptionModalComponent  {

    @Input()
    public description = '';

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public resolve = (value: EditionCancelMemberResult | null = null) => { /* */ };

    public result = new EditionCancelMemberResult();

    constructor(private overlayService: OverlayService) { }

    @HostListener('document:keyup.escape', ['$event'])
    public cancel(): void {

        this.resolve(null);

        this.overlayService.closeModal();
    }

    @HostListener('document:keyup.enter', ['$event'])
    public confirm(): void {

        this.resolve(this.result);

        this.overlayService.closeModal();
    }
}
