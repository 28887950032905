import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsubscriptionComponent } from './components/unsubscription/unsubscription.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        UnsubscriptionComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule
    ]
})

export class UnsubscriptionModule { }
