import { Account } from '../entities/account.entity';
import { PaginedRequest, PaginedResponse } from './pagined.dto';
import { AccountRoles } from '../enums/account-roles.enum';
import { AccountStatus } from '../enums/account-status.enum';



export class AccountsSearchFilters {

    public id?: string;

    public email?: string;

    public name?: string;

    // public status?: AccountStatus;

    public role?: AccountRoles;
}

export class AccountsSearchRequest extends PaginedRequest {

    public id?: string;

    public email?: string;

    public name?: string;

    public status?: AccountStatus;

    public role?: AccountRoles;
}

export class AccountsSearchResponse extends PaginedResponse {

    public items!: Array<Account>;
}

export class SigninRequest {

    public name = '';

    public email = '';

    public password = '';
}

export class SigninConfirmRequest {

    public email = '';

    public code = '';
}

export class LoginWithGoogleRequest {

    public code = '';
}

export class RenewPasswordRequest {

    public email = '';
}

export class RenewPasswordConfirmRequest {

    public email = '';

    public password = '';

    public code = '';
}

export class LoginRequest {

    public email = '';

    public password = '';
}

export class LoginResponse {

    public token = '';
}
