
export class EventsDashboard {

    public total = 0;

    // ...
}

export class Dashboard {

    public events = new EventsDashboard();
}
