import { ApiProperty, IsDate, IsDateString, IsNotEmpty, IsOptional } from '@kireidy/decorators';

import { Member } from '../entities/member.entity';
import { XEvent, XEventQuestion, XEventResponse } from '../entities/event.entity';

import { PaginedRequest, PaginedResponse } from './pagined.dto';
import { Expose } from 'class-transformer';


export class XEventsSearchRequest extends PaginedRequest {

    @ApiProperty()
    public id?: string;

    @ApiProperty()
    public email?: string;

    @ApiProperty()
    @Expose()
    @IsOptional()
    @IsDateString()
    public date?: string;

    @ApiProperty()
    public name?: string;
}

export class XEventsSearchResponse extends PaginedResponse {

    public items!: Array<XEvent>;
}


// The create event request is an event excepted:
// - It doens't have an id or a key yet
// - Members are manageed separately
// - It can bring an image property

export class CreateXEventRequest {

    @ApiProperty()
    @IsNotEmpty()               // Marked as 'required: true' in the mongoDB model
    public name = '';

    @ApiProperty()
    public description = '';

    @ApiProperty()
    @IsNotEmpty()               // Marked as 'required: true' in the mongoDB model
    public organisator = '';

    @ApiProperty()
    public location = '';

    @ApiProperty()
    public imagePosition = '';

    @ApiProperty()
    @IsNotEmpty()               // Marked as 'required: true' in the mongoDB model
    public date = new Date();

    @ApiProperty()
    public limit = 0;

    @ApiProperty()
    @IsNotEmpty()               // Marked as 'required: true' in the mongoDB model
    public email = '';

    @ApiProperty()
    public image?: string;

    @ApiProperty()
    public isSimplifiedInscription = false;

    @ApiProperty()
    public isTimedReading = false;
}

export class CreateXEventResponse extends XEvent { }


export class DeleteXEventRequest {

    @ApiProperty()
    public key!: string;
}

export class DeleteXEventResponse {

}


export class SubscribeMemberRequest {

    @ApiProperty()
    public eventId = '';

    @ApiProperty()
    public pseudo = '';

    @ApiProperty()
    public group?: string;

    @ApiProperty()
    public firstName?: string;

    @ApiProperty()
    public lastName?: string;

    @ApiProperty()
    public phone?: string;

    @ApiProperty()
    public dob?: Date;

    @ApiProperty()
    public responses = new Array<XEventResponse>();

    @ApiProperty()
    public email = '';
}

export class SubscribeMemberResponse {

    @ApiProperty()
    public members = new Array<Member>();
}

export class UnsubscribeMemberRequest {

    @ApiProperty()
    public reason = '';

    @ApiProperty()
    public key = '';
}

export class UnsubscribeMemberResponse {

    public members = new Array<Member>();
}

export class UpdateXEventRequest {

    @ApiProperty()
    public name = '';

    @ApiProperty()
    public description = '';

    @ApiProperty()
    public organisator = '';

    @ApiProperty()
    public location = '';

    @ApiProperty()
    public imagePosition = '';

    @ApiProperty()
    public date = new Date();

    @ApiProperty()
    public limit = 0;

    @ApiProperty()
    public email = '';

    @ApiProperty()
    public key = '';

    @ApiProperty()
    public image?: string;

    @ApiProperty()
    public isSimplifiedInscription = false;
}
